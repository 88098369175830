body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url('logo.jpg');
  background-repeat: no-repeat;
}

.active {
  font-weight: bold;
}
#mainMenu{
  position: absolute;
  top:170px;
}

#mainMenu button{
  clear: right;
  width:140px;
  display: block;
}

#search{
  width:140px;
}

#mainContent{
  margin-top: 90px;
  margin-left: 170px;
  margin-right: 10px;
}

.hidden{
  display: none;
}
